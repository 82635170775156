<template>
  <div id="app">
    <span v-if="isDevMode">
      <dev-mode-skip-buttons></dev-mode-skip-buttons>
    </span>
    
    
    <div class="min-h-screen fb flex ">
      <div class="hidden lg:block relative w-0 flex-1">
        <div class="gradient-animate absolute inset-0 h-full w-full object-cover">
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import DevModeSkipButtons from './components/common/DevModeSkipButtons.vue';

export default {
  name: "app",
  components: {DevModeSkipButtons},
  computed: {
    isDevMode() {
      return this.$developerMode
    }
  },
  created: function() {
        if (this.$supabase.auth.session() == null) {
          // no session or cookie present.
          console.log("No auth session detecetd.")
          this.$router.push("/")
        } else {
          // redirect them
          if (this.$developerMode == true){
            console.log("authed, but not redirecting due to developer mode")
          } else {
              window.location.href = "https://dashboard.invii.io"
          }
        }
  },
  data: function() {
    return {
      secondPage: false,
    };
  },
};
</script>
