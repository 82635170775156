<template>
  <div
    class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20"
  >
    <verification-method-selection ref="verificationModal"></verification-method-selection>
    <div class="mx-auto w-full ">
      <div>
        <img class="h-16 w-auto" src="../assets/logo.png" alt="Workflow" />
        <h2 class="main-text mt-6 text-3xl font-bold text-gray-900 ">
          Sign In
        </h2>
        <p class="mt-2 text-lg text-gray-600 max-w">
          Enter your email and password to sign in.
        </p>
      </div>

      <div class="mt-1">
        <div>
          <div class="mt-1 relative">
            <div
              class="absolute inset-0 flex items-center"
              aria-hidden="true"
            ></div>
            <div class="relative flex justify-center text-sm"></div>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

          <div class="sm:col-span-6">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="mt-1">
              <input
                v-model="emailAddress"
                name="email"
                type="email"
                autocomplete="email"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-6">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="password"
                name="email"
                type="password"
                autocomplete="password"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="mt-5 tac-confirm flex items-center">
          <label for="remember_me" class="block text-sm text-gray-900">
            Don't have an account?
            <span class="text-logo font-bold"
              ><a href="https://onboarding.invii.io/">Sign Up</a>.</span
            >
          </label>
        </div>
      </div>

      <div>
        <button
          @click="redirectWhenReady"
          type="submit"
          class="mt-5 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white "
          v-bind:class="{
            'bg-logo hover:bg-logoHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoDarker': !buttonDisabled,
            'bg-gray-600 opacity-25': buttonDisabled && !loadingButton,
            'bg-gray-500': buttonDisabled && loadingButton,
          }"
          v-bind:disabled="buttonDisabled"
        >
          <span v-if="!buttonDisabled">Sign In</span>
          <span v-if="buttonDisabled && !loadingButton">
            Unavailable
          </span>
          <span v-if="buttonDisabled && loadingButton">
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        </button>
        <form-errors :passedErrorMessages="errors"></form-errors>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrors from "./common/formErrors.vue";

import isEmail from "validator/lib/isEmail";
import VerificationMethodSelection from './verificationMethodSelection.vue';
const ERRORCODES = {
  e01: "Email Address is Invalid.",
  tosF: "You need to accept our Terms of Service and our Privacy Policy.",
  n01: "Please enter a valid first and last name.",
  e02: "This email address is already registered with an account.",
  e03: "There is a problem with this email. Try again.",
  e04: "There is a problem communicating with our services. Try again.",
  dev0: "This is a development error. don't stress about it",
  pw1: "Password not long enough.",
  pw2: "Password contains invalid characters.",
  pc1: "Passwords do not match.",
  pc3: "Email/Password is invalid."
};
export default {
  components: { FormErrors, VerificationMethodSelection, },
  name: "SignIn",
  data: function() {
    return {
      firstName: "",
      lastName: "",
      emailAddress: "",
      termsOfServiceBox: false,
      phone_number: null,
      errors: [],
      password: "",
      passwordConfirm: "",
      country: "",
      phoneCountry: "",
      // button stuff
      buttonDisabled: false,
      loadingButton: false,

      hasEmailBeenRegistered: null,
    };
  },
  watch: {
    termsOfServiceBox() {
      console.log("new tos", this.termsOfServiceBox);
    }
  },
  computed: {
    supportedCountryCodes() {
      return this.$store.state.supportedCountryCodes
    },
    supportedCountries(){
      return this.$store.state.supportedCountries
    }
  },
  methods: {


    isPasswordStrong() {
      // kj8bd2PDsFPjKS98

      if (this.password.length > 12){
        return true
      } else {
        return "pw1"
      }
    },
    doesPasswordMatch() {
      if (this.password != this.passwordConfirm) {
        return "pc1";
      } else {
        return true;
      }
    },
    removeError(code) {
      this.errors = this.errors.filter((error) => error.code == code);
    },

    pushNewError(code) {
      this.errors.push({ code: code, message: ERRORCODES[code] });
    },

    convertCountry(country_code){
      return this.supportedCountryCodes.fr[country_code]
    },

    async redirectWhenReady() {
      if (this.buttonDisabled) {
        console.log("Button has been disabled. ");
        return;
      }


      this.buttonDisabled = true;
      this.loadingButton = true;
      var anyErrors = [
        this.isEmailValid(),
      ];
      var filterTrue = anyErrors.filter((result) => result != true);

      console.log("Errors", filterTrue);

      if (filterTrue.length == 0) {
        //this.$router.push("/confirm/email");
                
        // eslint-disable-next-line no-unused-vars
        const { user, session, error } = await this.$supabase.auth.signIn({
          email: this.emailAddress,
          password: this.password,
        })

        

        if (error) {
          console.log("Error", error);
          this.pushNewError("pc3");
          this.buttonDisabled = false;
          this.loadingButton = false;
          return;
        } else {
          console.log("Invii: Authenticated")
          window.location.href = "https://dashboard.invii.io"
        }

        //console.log("requesting next push");
      } else {
        // lmfao i fucking miss list comprehensions
        let correctDefinedErrors = [];

        for (let i = 0; i < filterTrue.length; i++) {
          correctDefinedErrors.push({
            code: filterTrue[i],
            message: ERRORCODES[filterTrue[i]],
          });
        }
        this.errors = correctDefinedErrors;
        this.buttonDisabled = false;
      }
    },
    // buttonDisabled
    isEmailValid() {
      if (isEmail(this.emailAddress) == false) {
        console.log("Invalid email address", this.emailAddress);
        return "e01";
      } else {
        return true;
      }
    },
    userAgreedToTerms() {
      if (this.termsOfServiceBox == false) {
        return "tosF";
      } else {
        return true;
      }
    },

    userSetFirstLastName() {
      if (
        this.firstName == "" ||
        this.lastName == "" ||
        /\d/.test(this.firstName) ||
        /\d/.test(this.lastName)
      ) {
        //this.pushNewError("n01");
        return "n01";
      } else {
        return true;
      }
    },
  },
};
</script>
