<template>
  <span>
    <div
      class="flex-1 flex flex-col h-screen justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-16 w-auto" src="../assets/logo.png" alt="Workflow" />
          <h2 class="main-text mt-6 text-3xl font-bold text-gray-900">
            Something went wrong.
          </h2>
          <p class="mt-2 text-sm text-gray-600 max-w">
            Please supply our support team with this error code.
          </p>
          <p class="mt-2 text-xs text-gray-600 max-w">
            <span
              class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
            >
              {{ $route.params.em }}
            </span>


          </p>

        </div>

        <div class="mt-10">
                          <router-link to="/">
                                <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-logo hover:bg-logoHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo">
    Return to sign-in page
  </button>
                </router-link>
          <div>
            <div class="mt-1 relative">


              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              ></div>
              <div class="relative flex justify-center text-sm"></div>
            </div>
          </div>
        </div>
        <need-some-help></need-some-help>
      </div>
    </div>

  </span>
</template>

<script>
import needSomeHelp from "./common/needSomeHelp.vue";

export default {
  components: { needSomeHelp },
  name: "ErrorPage"
}

</script>

<style></style>
