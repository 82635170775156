<template>
          <p class="mt-5 text-xs text-gray-600 max-w">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
          >
            API v{{apiVersion}}
          </span>
        </p>
</template>

<script>
export default {
  name: "APIVersion",
    computed: {
        apiVersion() {
            return this.$store.state.apiVersion
        }
    }
}
</script>

<style>

</style>