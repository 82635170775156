import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import Vuex from 'vuex'
import VueRouter from 'vue-router'


var VueCookie = require('vue-cookie');
import SignIn from "./components/SignIn.vue"
import EmailandPasswordConfirm from "./components/EmailandPasswordConfirm.vue"
import {createClient} from "@supabase/supabase-js"
import ErrorPage from "./components/errorPage.vue"
import * as Sentry from "@sentry/vue";
import Fragment from 'vue-fragment'
Sentry.init({
  Vue: Vue,
  dsn: "https://272196cc9fde4b84ae01a7491d5a9383@o553233.ingest.sentry.io/5680225",
  logErrors: true,
  beforeSend(event) {
    if (event.password) {
      delete event.user.password
    }
    return event
  }
});

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Fragment.Plugin)
Vue.use(VueCookie)

const store = new Vuex.Store({
  state: {
    // User information
    sessionId: null,
    availableOtpMethods: [],
    tempAuthSessionKey: null,
    verificationMethod: null
  },
  mutations: {
    setSessionID(state, sessionId){
      state.sessionId = sessionId
    },
    setVerificationMethod(state, verificationMethod){
      state.verificationMethod = verificationMethod
    },
    setAvailableOtpMethods(state, otpMethods){
      state.availableOtpMethods = otpMethods
    },
    setTempAuthSessionKey(state, newTempKey){
      state.tempAuthSessionKey = newTempKey
    }
  },
  getters: {
    sessionId(state){
      return state.sessionId
    },
    availableOtpMethods(state){
      return state.availableOtpMethods
    },
    tempAuthSessionKey(state){
      return state.tempAuthSessionKey
    },
    verificationMethod(state){
      return state.verificationMethod
    }
  }
})

Vue.config.productionTip = false
Vue.prototype.$developerMode = false
Vue.prototype.$supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL, process.env.VUE_APP_SUPABASE_API_KEY
)
const jsonFetch = async (input, init) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) =>
    fetch(input, init)
      .then(async req => {
        if (!req.ok) {
          const errorText = await req.text()
          return reject(new Error(errorText))
        }
        return req.json()
      })
      .then(resolve)
      .catch(reject)
  )


Vue.prototype.$fetch = jsonFetch


const routes = [
  { path: "/", component: SignIn, name: "home" },
  { path: "/confirm/email", component: EmailandPasswordConfirm },
  //{ path: "/create/identity", component: sendEmailVerification },
  { path: "/error/:em", component: ErrorPage}
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})



const initialStateCopy = JSON.parse(JSON.stringify(store.state))

Vue.prototype.$resetState = function() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
