<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
<span class="relative z-0 inline-flex shadow-sm rounded-md">
<router-link to="/confirm/email">
    <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md bg-gray-100 text-gray-800 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      skip to SMS/Email verification
    </button>
</router-link>
<router-link to="/stripe/connect">
    <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md bg-gray-100 text-gray-800 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      skip to StripeConnect
    </button>
</router-link>
<router-link to="/register">
    <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md bg-gray-100 text-gray-800 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      skip to register
    </button>
</router-link>
DEV MODE IS ON
</span>

</template>

<script>
export default {

}
</script>

<style>

</style>