<template>
  <fragment>
    <span v-if="userNext">
      <div
        v-if="error"
        class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:mr-20"
      >
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img class="h-16 w-auto" src="../assets/logo.png" alt="Workflow" />
            <h2 class="main-text mt-6 text-3xl font-bold text-gray-900">
              Something went wrong.
            </h2>
            <p class="mt-2 text-sm text-gray-600 max-w">
              {{ errorMessage }}
            </p>
            <p class="mt-2 text-xs text-gray-600 max-w">
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
              >
                Error Code: {{ errorCode }}
              </span>
            </p>
          </div>

          <div class="mt-1">
            <div>
              <div class="mt-1 relative">
                <div
                  class="absolute inset-0 flex items-center"
                  aria-hidden="true"
                ></div>
                <div class="relative flex justify-center text-sm"></div>
              </div>
            </div>
          </div>
          <need-some-help></need-some-help>
          <p class="text-xs text-gray-600 max-w">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
          >
            API v1.0
          </span>
        </p>
        </div>
      </div>
      <div
        v-if="!error"
        class="flex-1 flex h-screen flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
      >
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img class="h-16 w-auto" src="../assets/logo.png" alt="Workflow" />
            <h2 class="main-text mt-6 text-3xl font-bold text-gray-900">
              Give us a second.
            </h2>
            <p class="mt-2 text-lg text-gray-600 max-w">
              We're getting things ready.
            </p>
          </div>

          <div class="mt-1">
            <div>
              <div class="mt-1 relative">
                <div
                  class="absolute inset-0 flex items-center"
                  aria-hidden="true"
                ></div>
                <div class="relative flex justify-center text-sm"></div>
              </div>
            </div>
          </div>
          <need-some-help></need-some-help>
          <p class="text-xs text-gray-600 max-w">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
          >
            API v1.0
          </span>
        </p>
        </div>
      </div>
    </span>

    <span
      v-if="!userNext"
      class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:mr-20"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-16 w-auto" src="../assets/logo.png" alt="Workflow" />
          <h2 class="main-text mt-6 text-3xl font-bold text-gray-900">
            Enter Verification Code
          </h2>
          <p class="mt-2 text-sm text-gray-600 max-w" v-if="methodUsedForVerification === 'email'">
            We've sent a code to your email address linked to your account to verify this login.
          </p>
          <p class="mt-2 text-sm text-gray-600 max-w" v-if="methodUsedForVerification === 'phone'">
            We've sent a message to your phone number linked to your account to verify this login.
          </p>
          <p class="mt-2 text-sm text-gray-600 max-w" v-if="methodUsedForVerification === 'app'">
            We've sent a message to your authentication app linked to your account to verify this login.
          </p>
        </div>

        <div class="mt-1">
          <div>
            <div class="mt-1 relative">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              ></div>
              <div class="relative flex justify-center text-sm"></div>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700"
              >
                Verification Code
              </label>
              <div class="container -ml-3 mt-2 lg:ml-14">
                <vue-auth-code-input
                  @inputChange="handleVerificationCodeComplete"
                  class="ml-4"
                  v-model="verificationCode"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            @click="redirectWhenReady"
            type="submit"
            class="mt-5 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-logo hover:bg-logoHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoDarker"
          >
            Verify
          </button>
          <form-errors :passedErrorMessages="errors"></form-errors>
        </div>
<APIVersion></APIVersion>

        <div class="sm:col-span-6 mt-5">
              <p class="max-w-2xl text-sm text-gray-500">
                Didn't recieve a code?
                <span class="text-logo font-bold">
                  <a >Resend.</a>
                </span>
              </p>
            </div>

      </div>
    </span>
  </fragment>
</template>

<script>
import VueAuthCodeInput from "vue-auth-code-input";
import NeedSomeHelp from "./common/needSomeHelp.vue";
import FormErrors from "./common/formErrors.vue";
import APIVersion from './common/APIVersion.vue';
var strength = require("strength");
const axios = require("axios");

const ERRORCODES = {
  pw1: "Password not strong enough.",
  pw2: "Password contains invalid characters.",
  pc1: "Passwords do not match.",
  v1: "Please enter a valid verification code.",
  v2: "Verification Code is Invalid.",
};

export default {
  name: "EmailandPasswordConfirm",
  components: { VueAuthCodeInput, NeedSomeHelp, FormErrors, APIVersion },
  data: function() {
    return {
      verificationCode: "",
      verificationCodeArray: [],
      errors: [],
      

      userNext: false,
      error: false,
      errorMessage: "",
      errorCode: "",
    };
  },
  
  mounted(){
    this.$nextTick(function () {
          let self = this;
          var debugMode = this.$developerMode;
          if (debugMode == false){
            
          axios
            .post(`https://wonderwall-dev.invii.io/authentication/exchange/credentials/stage-two`, {
              otp_method_selected: self.methodUsedForVerification,
            }, {headers: {
              "x-invii-crd-auth-temp":  self.$store.getters.tempAuthSessionKey
            }})
            .catch(function(response){
              self.$router.push(`/error/${response.response.data.code}`)
            })
          } else {
            console.log("Developer mode is active. Will not send verification to ", self.methodUsedForVerification, "with key", self.temporaryAuthorizationKey)
          }

    })
  },
  computed: {
    methodUsedForVerification() {
      return this.$store.getters.verificationMethod
    },
    temporaryAuthorizationKey() {
      return this.$store.getters.tempAuthSessionKey
    },
  },
  watch: {
    errors() {
      console.log("errors updated", this.errors);
    },
  },
  methods: {
    sendVerificationCodeAgain() {
      // TODO
    },
    redirectWhenReady() {
      var anyErrors = [
        this.isVerificationCodeLongEnough(),
      ];
      var filterTrue = anyErrors.filter((result) => result != true);

      console.log("Errors", filterTrue);
      if (filterTrue.length == 0) {
        // True IF YOU ARE DEBUGGING to skip to stripe
        var debugMode = this.$developerMode;

        if (debugMode == false) {
          //et self = this;


          this.$fetch("https://wonderwall-dev.invii.io/authentication/exchange/credentials/stage-three", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              "x-invii-crd-auth-temp":  this.$store.getters.tempAuthSessionKey 
          },
            body: JSON.stringify({
              authentication_code: this.verificationCode,
              otp_method_selected: this.methodUsedForVerification,
            }),
            credentials: 'include',
            
          })
            .then((response) => {
              console.log("response", response);
              if (response.data.code == "200") {
                //this.$store.commit("setUser", response.data.data);
                console.log("Got session ID. Check ")
              } else {
                this.errors = [response.data.message];
              }
            })
            .catch((error) => {
              console.log("error", error);
              this.$router.push("/error/500");
            });
        } else {
          this.$router.push("/stripe/connect")
        }

        if (this.$store.getters.identitySet != null) {
          this.$router.push("/");
        }
      } else {
        // lmfao i fucking miss list comprehensions
        let correctDefinedErrors = [];

        for (let i = 0; i < filterTrue.length; i++) {
          correctDefinedErrors.push({
            code: filterTrue[i],
            message: ERRORCODES[filterTrue[i]],
          });
        }
        this.errors = correctDefinedErrors;
      }
    },

    removeError(code) {
      this.errors = this.errors.filter((error) => error.code == code);
    },

    pushNewError(code) {
      this.errors.push({ code: code, message: ERRORCODES[code] });
    },
    pushNewErrorWithMessage(code, message) {
      this.errors.push({ code: code, message: message });
    },
    isPasswordStrong() {
      // kj8bd2PDsFPjKS98

      var result = strength(this.password);
      console.log(result);
      if (result <= 2.0) {
        //this.pushNewError("pw1");
        return "pw1";
      } else {
        return true;
      }
    },
    doesPasswordMatch() {
      if (this.password != this.passwordConfirm) {
        return "pc1";
      } else {
        return true;
      }
    },

    handleVerificationCodeComplete(codeArray) {
      this.verificationCode = codeArray.join();
      this.verificationCodeArray = codeArray;
    },
    isVerificationCodeLongEnough() {
      if (this.verificationCodeArray.length != 6) {
        return "v1";
      } else {
        this.verificationCode = this.verificationCodeArray.join("");
        console.log("Verification code input", this.verificationCode);
        return true;
      }
    },
  },
};
</script>
