<template>
  <span>
    <div class="bg-gray-100 sm:rounded-lg mt-10">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Need some help?
          <span
            class="inline-flex items-center px-2 py-0.5 float-right rounded text-xs font-medium bg-green-100 text-green-800"
          >
            <svg
              class="mr-1.5 h-2 w-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx="4" cy="4" r="3" />
            </svg>
            Support is online.
          </span>
        </h3>

        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            No problem. Our support team is happy to help with the onboarding
            process with you over phone, live chat or Zoom.
          </p>
        </div>
        <div class="mt-5">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <span
        class="inline-flex float-right items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
      >
        v{{ applicationVersion }}
      </span>
    </div>
  </span>
</template>

<script>
export default {
  name: "NeedSomeHelp",
  data() {
    return {
      applicationVersion: this.$store.getters.applicationVersion,
    };
  },
};
</script>

<style></style>
