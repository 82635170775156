<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-bind:class="{'hidden': !show}"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-logo"
          >
            <!-- Heroicon name: solid/shield-check -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="white">
              <path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-bold text-gray-900"
              id="modal-title"
            >
              Two-Factor Authentication
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Please select a verification method in order to login to your
                account.
              </p>
            </div>
            <div class="mt-5">
              <select
                id="location"
                name="location"
                v-model="verificationMethodSelected"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option v-for="item in methodsAvailable" v-bind:key="item.key" v-bind:value="item.key">{{item.value}}</option>

              </select>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <button
          @click="commitData"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-logo text-base font-medium text-white hover:bg-logoHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoHover sm:text-sm"
          >
            Select
          </button>
        <button @click="hide" type="button" class="mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "VerificationMethodSelection",
  data: function() {
      return {
          verificationMethodSelected: null,
          show: false
      }
  },
  computed: {
      methodsAvailable() {
          // convert array to objects
            return this.$store.state.availableOtpMethods
      }
  },
  methods: {
      display() {
          this.show = true;
      },
      hide() {
          this.show = false
      },
      commitData(){
          this.$store.commit('setVerificationMethod', this.verificationMethodSelected);
          this.hide()
          this.$router.push("/confirm/email")
      },
      resetData() {
          this.$resetState();
          this.hide()
      }
  }

};
</script>
